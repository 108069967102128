import { Component, Vue, Prop } from 'vue-property-decorator';
import { Report, statusProblems } from '@/models/Report';

@Component<ProblemIMGDialog>({})
export default class ProblemIMGDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected statusProblems = statusProblems;

  protected selectedStatusProblem = '';

  protected close() {
    this.$emit('input', false);
  }

  protected confirmProblem() {
    this.report
      .update({
        status: 'problem_tcmg',
        problem_key: this.selectedStatusProblem,
      })
      .then(() => {
        this.$emit('reload');
        this.close();
      });
  }
}
