var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "div",
        { staticClass: "elementPanel" },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", {
                        attrs: {
                          flat: "",
                          type: "spinner--center",
                          minHeight: "300px"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isLoading
            ? [
                [
                  _c(
                    "v-layout",
                    {
                      staticClass: "profileContainer py-0",
                      attrs: { wrap: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs4: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                {
                                  staticClass: "label",
                                  attrs: { xs12: "", "pb-0": "" }
                                },
                                [_vm._v("Reden voor vemelding probleem:")]
                              ),
                              _c(
                                "v-flex",
                                {
                                  staticClass:
                                    "value disabled font-weight-bold",
                                  attrs: { xs12: "", "pt-0": "" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.currentProblemKeyLabel) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "text-right", attrs: { xs8: "" } },
                        [
                          _vm.canReset()
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "mr10",
                                  attrs: { color: "warning", outline: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.resetManagementInfo($event)
                                    }
                                  }
                                },
                                [_vm._v(" Herbereken ")]
                              )
                            : _vm._e(),
                          _vm.canReset() &&
                          _vm.$store.state.isServiceOrganization &&
                          _vm.$store.state.Auth.has_management_info
                            ? _c(
                                "MiButton",
                                {
                                  staticClass: "tw-mr-8",
                                  attrs: { color: "primary", outline: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.showRuntimeExemptionDialog = true
                                    }
                                  }
                                },
                                [_vm._v(" Vrijstelling van doorlooptijd ")]
                              )
                            : _vm._e(),
                          _vm.allowEdit
                            ? _c(
                                "MiButton",
                                {
                                  attrs: { color: "warning", outline: "true" },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      _vm.showConfirmProblemDialog = true
                                    }
                                  }
                                },
                                [_vm._v(" Probleem IMG ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "", "mt-4": "" } }, [
                        _c("h3", [_vm._v("First Time Right")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "item__list", attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { staticClass: "label", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "" } },
                            [
                              _c("v-flex", { attrs: { xs2: "" } }, [
                                _vm._v("Partij")
                              ]),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm._v("Resultaat")
                              ]),
                              _c("v-flex", { attrs: { xs2: "" } }, [
                                _vm._v("Reden(en)")
                              ]),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm._v("Toelichting")
                              ]),
                              _c("v-flex", { attrs: { xs2: "" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "list__item", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-center": "" } },
                            [
                              _c("v-flex", { attrs: { xs2: "" } }, [
                                _vm._v("IMG")
                              ]),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm.report.stats_first_time_right_tcmg
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "statusPill statusBackgroundColor--green"
                                      },
                                      [_vm._v(" First Time Right ")]
                                    )
                                  : _vm._e(),
                                !_vm.report.stats_first_time_right_tcmg
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "statusPill statusBackgroundColor--red"
                                      },
                                      [_vm._v(" NOT First Time Right ")]
                                    )
                                  : _vm._e()
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                _vm._l(
                                  _vm.report
                                    .stats_first_time_right_tcmg_reasons,
                                  function(reason) {
                                    return _c("span", { key: reason }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parseFirstTimeRightName(
                                              reason,
                                              true
                                            )
                                          ) +
                                          ", "
                                      ),
                                      _c("br")
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.report
                                        .stats_first_time_right_tcmg_comment
                                    ) +
                                    " "
                                )
                              ]),
                              !_vm.syncing && !_vm.report.is_offline
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "item__icon",
                                      attrs: { xs2: "", "text-xs-right": "" }
                                    },
                                    [
                                      !_vm.$store.state.isServiceOrganization &&
                                      _vm.$store.state.Auth.has_management_info
                                        ? _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.editFirstTimeRight(
                                                    _vm.firstTimeRights[0]
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" edit ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { staticClass: "list__item", attrs: { xs12: "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", "align-center": "" } },
                            [
                              _c("v-flex", { attrs: { xs2: "" } }, [
                                _vm._v("Bureau")
                              ]),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm.report.stats_first_time_right_bureau
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "statusPill statusBackgroundColor--green"
                                      },
                                      [_vm._v(" First Time Right ")]
                                    )
                                  : _vm._e(),
                                !_vm.report.stats_first_time_right_bureau
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "statusPill statusBackgroundColor--red"
                                      },
                                      [_vm._v(" NOT First Time Right ")]
                                    )
                                  : _vm._e()
                              ]),
                              _c(
                                "v-flex",
                                { attrs: { xs2: "" } },
                                _vm._l(
                                  _vm.report
                                    .stats_first_time_right_bureau_reasons,
                                  function(reason) {
                                    return _c("span", { key: reason }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.parseFirstTimeRightName(
                                              reason,
                                              false
                                            )
                                          ) +
                                          ", "
                                      ),
                                      _c("br")
                                    ])
                                  }
                                ),
                                0
                              ),
                              _c("v-flex", { attrs: { xs3: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.report
                                        .stats_first_time_right_bureau_comment
                                    ) +
                                    " "
                                )
                              ]),
                              !_vm.syncing && !_vm.report.is_offline
                                ? _c(
                                    "v-flex",
                                    {
                                      staticClass: "item__icon",
                                      attrs: { xs2: "", "text-xs-right": "" }
                                    },
                                    [
                                      _vm.$store.state.isServiceOrganization &&
                                      _vm.$store.state.Auth.has_management_info
                                        ? _c(
                                            "v-icon",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.editFirstTimeRight(
                                                    _vm.firstTimeRights[1]
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" edit ")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("Doorlooptijd")])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "overflow-auto" },
                    [
                      [
                        _c(
                          "table",
                          { staticClass: "item__list item__list--table my-4" },
                          [
                            _c("thead", [
                              _c(
                                "tr",
                                {
                                  staticClass: "label header__row",
                                  attrs: {
                                    wrap: "",
                                    row: "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("td", [_vm._v("Datum")]),
                                  _c("td", [_vm._v("Status")]),
                                  _c("td", [_vm._v("Door")]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v("WV")
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v("Planning")
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v("Externe")
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v("Bureau")
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v("Veldwerk")
                                  ])
                                ]
                              )
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.reportLogs, function(
                                reportLog,
                                index
                              ) {
                                return _c(
                                  "tr",
                                  {
                                    key: "day-" + index,
                                    staticClass: "list__item"
                                  },
                                  [
                                    _c(
                                      "td",
                                      [
                                        _vm.isAtabix()
                                          ? _c(
                                              "v-icon",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.deleteLog(
                                                      reportLog,
                                                      index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("delete")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("dateFormatTime")(
                                                reportLog.created_at
                                              )
                                            ) +
                                            " "
                                        )
                                      ],
                                      1
                                    ),
                                    _c("td", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "statusPill",
                                          class:
                                            "statusBackgroundColor--" +
                                            _vm.getStatusColor(
                                              reportLog.changed_to
                                            )
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                reportLog.changed_to.length
                                                  ? _vm.getStatusLabel(
                                                      reportLog.changed_to
                                                    )
                                                  : ""
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("td", [
                                      reportLog.organization
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    reportLog.organization.name
                                                  ) +
                                                  " | " +
                                                  _vm._s(reportLog.user) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      !reportLog.organization
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "bold"
                                              }
                                            },
                                            [_vm._v(_vm._s(reportLog.user))]
                                          )
                                        : _vm._e()
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(
                                        _vm._s(
                                          reportLog.balance.werkvoorbereiding
                                        )
                                      )
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(_vm._s(reportLog.balance.planning))
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(_vm._s(reportLog.balance.external))
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(_vm._s(reportLog.balance.bureau))
                                    ]),
                                    _c("td", { staticClass: "balance" }, [
                                      _vm._v(_vm._s(reportLog.balance.veldwerk))
                                    ])
                                  ]
                                )
                              }),
                              0
                            ),
                            _c("tfoot", [
                              _c(
                                "tr",
                                {
                                  staticClass: "label header__row",
                                  attrs: {
                                    wrap: "",
                                    row: "",
                                    "align-center": ""
                                  }
                                },
                                [
                                  _c("td"),
                                  _c("td"),
                                  _c("td", [_vm._v("Totaal")]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalBalanceStatusChanges(
                                          "werkvoorbereiding"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalBalanceStatusChanges(
                                          "planning"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalBalanceStatusChanges(
                                          "external"
                                        )
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalBalanceStatusChanges("bureau")
                                      )
                                    )
                                  ]),
                                  _c("td", { staticClass: "balance" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.totalBalanceStatusChanges(
                                          "veldwerk"
                                        )
                                      )
                                    )
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    ],
                    2
                  )
                ],
                _vm.$store.state.Auth.is_rockefeller
                  ? [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c("v-flex", { attrs: { xs12: "" } }, [
                            _c("h3", [_vm._v("Kosten")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-layout",
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c("DataTable", {
                                ref: "specificationDatatable",
                                attrs: {
                                  options: _vm.tableOptionsSpecificationLines,
                                  visibility: _vm.visibility
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.isEditingFirstTimeRight
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.isEditingFirstTimeRight,
                    callback: function($$v) {
                      _vm.isEditingFirstTimeRight = $$v
                    },
                    expression: "isEditingFirstTimeRight"
                  }
                },
                [
                  _c("FirstTimeRightDialog", {
                    attrs: {
                      report: _vm.report,
                      selected: _vm.selectedFirstTimeRight,
                      isDisabled: _vm.isDisabledFirstTimeRight
                    },
                    on: { firstTimeRightUpdated: _vm.firstTimeRightUpdated },
                    model: {
                      value: _vm.isEditingFirstTimeRight,
                      callback: function($$v) {
                        _vm.isEditingFirstTimeRight = $$v
                      },
                      expression: "isEditingFirstTimeRight"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showConfirmProblemDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.showConfirmProblemDialog,
                    callback: function($$v) {
                      _vm.showConfirmProblemDialog = $$v
                    },
                    expression: "showConfirmProblemDialog"
                  }
                },
                [
                  _c("ProblemIMGDialog", {
                    attrs: { report: _vm.report },
                    on: { reload: _vm.reload },
                    model: {
                      value: _vm.showConfirmProblemDialog,
                      callback: function($$v) {
                        _vm.showConfirmProblemDialog = $$v
                      },
                      expression: "showConfirmProblemDialog"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.showRuntimeExemptionDialog
            ? _c(
                "v-dialog",
                {
                  attrs: { "max-width": "500" },
                  model: {
                    value: _vm.showRuntimeExemptionDialog,
                    callback: function($$v) {
                      _vm.showRuntimeExemptionDialog = $$v
                    },
                    expression: "showRuntimeExemptionDialog"
                  }
                },
                [
                  _c("RuntimeExemptionDialog", {
                    attrs: { report: _vm.report },
                    on: { reload: _vm.reload },
                    model: {
                      value: _vm.showRuntimeExemptionDialog,
                      callback: function($$v) {
                        _vm.showRuntimeExemptionDialog = $$v
                      },
                      expression: "showRuntimeExemptionDialog"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }