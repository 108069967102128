import { Organization } from '@/models/Organization';
import { Rpc } from '@/models/Rpc';
import { Component, Vue, Model, Prop } from 'vue-property-decorator';
import { Report as ReportModel, statusProblemsLabel } from '@/models/Report';
import { ReportLog } from '@/models/ReportLog';
import { getFirstTimeRightName } from '@/support/FirstTimeRight';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Options } from '@/components/mi-dialog/MiDialog';
import { getStatusColor, getStatusLabel } from '@/support/ReportStatus';
import FirstTimeRightDialog from '@/views/Reports/first-time-right-dialog/FirstTimeRightDialog.vue';
import { cloneDeep } from 'lodash';
import ProblemIMGDialog from '@/components/dialog/problem-img-dialog/ProblemIMGDialog.vue';
import RuntimeExemptionDialog from '@/components/dialog/runtime-exemption-dialog/RuntimeExemptionDialog.vue';
import { currency, formatDate, hours } from '@/support/String';
import { User } from '@/models/User';
import { TableOptions, TableVisibility } from '@/components/data-table/DataTable';
import { CostSpecificationLine, Duration } from '@/models/CostSpecificationLine';
import { CostSpecification } from '@/models/CostSpecification';
import { PermissionSlug } from '@/support/PermissionSlug';

@Component<ManagementInfo>({
  components: {
    FirstTimeRightDialog,
    ProblemIMGDialog,
    RuntimeExemptionDialog,
  },
})
export default class ManagementInfo extends Vue {
  public $pageTitle = 'Management Info';

  @Model('report', { default: () => new ReportModel() })
  protected report!: ReportModel;

  protected reportInfo: ReportModel | null = null;

  @Prop({ default: true })
  protected allowEdit!: boolean;

  @Prop({ default: false })
  protected syncing!: boolean;

  protected isLoading = true;

  protected reportLogs: ReportLog[] = [];

  protected selectedReportLog: ReportLog = new ReportLog();

  protected selectedIndex: number | null = null;

  protected costs: CostSpecificationLine[] = [];

  // firstimeright
  protected isEditingFirstTimeRight = false;

  protected isDisabledFirstTimeRight = false;

  protected selectedFirstTimeRight: any | null = null;

  protected firstTimeRights: any | null = null;

  protected showConfirmProblemDialog = false;

  protected showRuntimeExemptionDialog = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
    search: false,
  };

  public async mounted(): Promise<void> {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.isLoading = true;

    await this.fetchReportLogs();

    this.cloneReport();
    this.parseFirtTimeRights();
    this.isLoading = false;
  }

  protected cloneReport(): void {
    this.reportInfo = cloneDeep(this.report);
  }

  protected async fetchReportLogs(): Promise<void> {
    this.reportLogs = await new ReportLog()
      .filter({
        report: this.$route.params.id,
        type: 'status_change',
      })
      .include(['balance', 'organization'])
      .limit(1000)
      .sort('created_at', 'ASC')
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected totalBalanceStatusChanges(category : string): string {
    let total = 0;

    this.reportLogs.map((reportLog) => {
      if (! reportLog.balance) return;

      if (category === 'werkvoorbereiding') {
        total += reportLog.balance.werkvoorbereiding;
      }
      if (category === 'planning') {
        total += reportLog.balance.planning;
      }
      if (category === 'external') {
        total += reportLog.balance.external;
      }
      if (category === 'bureau') {
        total += reportLog.balance.bureau;
      }
      if (category === 'veldwerk') {
        total += reportLog.balance.veldwerk;
      }
    });

    return total.toFixed(2);
  }

  protected deleteLog(reportLog: ReportLog, index: number): void {
    if (! this.isAtabix()) {
      return;
    }

    this.selectedReportLog = reportLog;
    this.selectedIndex = index;
    this.$store.dispatch('openDialog', this.dialogOptionsDeleteReportLog);
  }

  protected get dialogOptionsDeleteReportLog(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je deze log wilt verwijderen? Met de uuid: ${this.selectedReportLog?.id}`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          action: () => {
            if (! this.selectedReportLog) {
              return;
            }

            this.selectedReportLog
              .delete()
              .then((reportLog: ReportLog) => {
                // removes from array instead of fetching all
                this.reportLogs.splice(this.selectedIndex as number, 1);
              })
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
              });
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedIndex = null;
          },
        },
      },
    };
  }

  protected parseFirstTimeRightName(value: string, tcmg: boolean) {
    return getFirstTimeRightName(value, tcmg);
  }

  protected getStatusColor(key: string) {
    return getStatusColor(key);
  }

  protected getStatusLabel(key: string) {
    return getStatusLabel(key);
  }

  protected canReset(): boolean {
    return this.$store.state.Auth?.can(PermissionSlug.MANAGEMENT_INFO_RESET);
  }

  protected isAtabix() {
    return this.$store.state.Auth.isAtabix || this.$store.state.isLocal;
  }

  protected resetManagementInfo() {
    new Rpc()
      .rpcPost({
        signature: 'management-info:reset',
        body: {
          report: this.report.uuid,
        },
      })
      .then(() => {
        this.reload();
      });
  }

  protected reload() {
    this.$emit('reload');
  }

  protected editFirstTimeRight(firstimeright: any, disabled = false) {
    this.selectedFirstTimeRight = firstimeright;
    this.isEditingFirstTimeRight = true;
    this.isDisabledFirstTimeRight = disabled;
  }

  protected parseFirtTimeRights() {
    if (! this.reportInfo) {
      return;
    }

    this.firstTimeRights = [
      {
        name: 'IMG',
        stats_first_time_right_tcmg: this.reportInfo.stats_first_time_right_tcmg,
        stats_first_time_right_tcmg_reasons: this.reportInfo.stats_first_time_right_tcmg_reasons,
        stats_first_time_right_tcmg_comment: this.reportInfo.stats_first_time_right_tcmg_comment,
      },
      {
        name: 'Bureau',
        stats_first_time_right_bureau: this.reportInfo.stats_first_time_right_bureau,
        stats_first_time_right_bureau_reasons: this.reportInfo.stats_first_time_right_bureau_reasons,
        stats_first_time_right_bureau_comment: this.reportInfo.stats_first_time_right_bureau_comment,
      },
    ];
  }

  protected firstTimeRightUpdated(report: ReportModel) {
    this.$emit('update:report', report);
    this.$set(this, 'reportInfo', report);
    this.parseFirtTimeRights();
  }

  protected get currentProblemKeyLabel() {
    if (! this.report.problem_key) {
      return '';
    }

    return statusProblemsLabel[this.report.problem_key];
  }

  protected get tableOptionsSpecificationLines(): TableOptions {
    return {
      model: new CostSpecificationLine()
        .include(['specification', 'organization', 'totals'])
        .filter({
          report: this.$route.params.id,
        })
        .sort('created_at', 'ASC'),
      headers: [
        {
          text: 'Bureau',
          value: 'organization',
          transform: (organization: Organization, costSpecificationLine: CostSpecificationLine) => {
            if (! organization) { return; }
            return organization.name;
          },
        },
        {
          text: 'Specificatie',
          value: 'specification',
          transform: (specification: CostSpecification, costSpecificationLine: CostSpecificationLine) => {
            if (! specification) { return; }
            return specification.specification_number;
          },
        },
        {
          text: 'Datum',
          value: 'date',
          sortable: {
            key: 'date',
            order: 'ASC',
          },
          transform: (date: string, costSpecificationLine: CostSpecificationLine) => {
            if (! date) { return; }
            return formatDate(date, 'dd-LL-yyyy');
          },
        },
        {
          text: 'Medewerker',
          value: 'user',
          transform: (user: User, costSpecificationLine: CostSpecificationLine) => {
            if (! user) {
              return '';
            }

            return user.name;
          },
        },
        {
          text: 'Uursoort',
          value: 'type',
        },
        {
          text: 'Uren',
          value: 'duration',
          class: 'text-right',
          sortable: {
            key: 'duration',
            order: 'DESC',
          },
          transform: (duration: Duration, costSpecificationLine: CostSpecificationLine) => {
            if (! duration) {
              return '';
            }

            return hours(duration.hours);
          },
        },
        {
          text: 'Tarief',
          value: 'unitprice',
          class: 'text-right',
          transform: (unitprice: number, costSpecificationLine: CostSpecificationLine) => {
            if (! unitprice) {
              return '';
            }

            return hours(unitprice);
          },
        },
        {
          text: 'Subtotaal',
          value: 'subtotal',
          class: 'text-right',
          sortable: {
            key: 'subtotal',
            order: 'DESC',
          },
          transform: (subtotal: number, costSpecificationLine: CostSpecificationLine) => {
            if (! subtotal) {
              return '';
            }

            return currency(subtotal, 'EUR');
          },
          visible: () => this.$store.state.Auth.is_rockefeller,
        },
      ],
      actions: [],
      filters: [],
      showTotals: true,
    };
  }
}
