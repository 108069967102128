var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "error-icon text-center" },
              [_c("v-icon", [_vm._v("warning")])],
              1
            ),
            _vm._v(" Vrijstelling van doorlooptijd aanvragen ")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c(
                        "v-menu",
                        {
                          ref: "dialog",
                          attrs: {
                            "close-on-content-click": false,
                            lazy: "",
                            "nudge-right": "100",
                            "full-width": "",
                            "max-width": "290px",
                            "min-width": "290px"
                          }
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "input input-date",
                            attrs: {
                              slot: "activator",
                              placeholder: "DD-MM-YYYY",
                              label: "Vrijgesteld van doorlooptijd t/m",
                              clearable: "",
                              "error-messages":
                                _vm.lastAppointmentAt.errorMessage
                            },
                            on: {
                              "click:clear": function($event) {
                                return _vm.lastAppointmentAt.clear()
                              }
                            },
                            slot: "activator",
                            model: {
                              value: _vm.lastAppointmentAt.formattedDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lastAppointmentAt,
                                  "formattedDate",
                                  $$v
                                )
                              },
                              expression: "lastAppointmentAt.formattedDate"
                            }
                          }),
                          _c("v-date-picker", {
                            ref: "picker",
                            attrs: {
                              "first-day-of-week": "1",
                              locale: "nl-nl",
                              min: "1910-01-01",
                              color: "#837f16"
                            },
                            model: {
                              value: _vm.lastAppointmentAt.selectedDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.lastAppointmentAt,
                                  "selectedDate",
                                  $$v
                                )
                              },
                              expression: "lastAppointmentAt.selectedDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.runtimeExemptionReasons,
                          "item-value": "value",
                          "item-text": "label",
                          label: "Reden",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedRuntimeExemptionReason,
                          callback: function($$v) {
                            _vm.selectedRuntimeExemptionReason = $$v
                          },
                          expression: "selectedRuntimeExemptionReason"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-textarea", {
                        attrs: { label: "Notitie" },
                        model: {
                          value: _vm.exemptionReasonAddition,
                          callback: function($$v) {
                            _vm.exemptionReasonAddition = $$v
                          },
                          expression: "exemptionReasonAddition"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "mi-button",
                          {
                            attrs: {
                              disabled:
                                !_vm.selectedRuntimeExemptionReason ||
                                !_vm.lastAppointmentAt.selectedDate,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.confirmExemption($event)
                              }
                            }
                          },
                          [_vm._v(" Verzenden ")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }