var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [
            _c(
              "div",
              { staticClass: "error-icon text-center" },
              [_c("v-icon", [_vm._v("warning")])],
              1
            ),
            _vm._v(" Verplaatsen naar: Probleem IMG ")
          ]),
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "" } },
                [
                  _c("v-flex", { attrs: { xs12: "" } }, [
                    _c("p", [
                      _vm._v(
                        "Weet je zeker dat je dit dossier terug wilt geven aan IMG?"
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "Gebruik dit alleen als er geen andere beschikbare manier is om dit dossier terug te geven aan IMG."
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Indien er geen passende reden tussen staat neem dan contact op met Functioneel Beheer Atabix ("
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: "mailto:helpdesk@schadedoormijnbouw.nl"
                          }
                        },
                        [_vm._v("helpdesk@schadedoormijnbouw.nl")]
                      ),
                      _vm._v("). ")
                    ])
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.statusProblems,
                          "item-value": "value",
                          "item-text": "label",
                          label: "Reden",
                          clearable: ""
                        },
                        model: {
                          value: _vm.selectedStatusProblem,
                          callback: function($$v) {
                            _vm.selectedStatusProblem = $$v
                          },
                          expression: "selectedStatusProblem"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: {
                              disabled: !_vm.selectedStatusProblem,
                              color: "primary",
                              small: "true"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.confirmProblem($event)
                              }
                            }
                          },
                          [_vm._v(" Ja, teruggeven ")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }