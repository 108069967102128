import { Component, Vue, Prop } from 'vue-property-decorator';
import { Rpc } from '@/models/Rpc';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import { Report, runtimeExemptionReasons, runtimeExemptionReasonsLabels } from '@/models/Report';
import { DateFormatter } from '@/support/DateFormatter';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';

@Component<RuntimeExemptionDialog>({
  components: {
    DefaultDialog,
  },
})
export default class RuntimeExemptionDialog extends Vue {
  @Prop()
  protected report!: Report;

  protected isLoading = false;

  protected lastAppointmentAt = new DateFormatter();

  protected runtimeExemptionReasons = runtimeExemptionReasons;

  protected selectedRuntimeExemptionReason = '';

  protected exemptionReasonAddition = '';

  protected close(): void {
    this.$emit('input', false);
  }

  protected confirmExemption(): void {
    console.log(this.report);
    const payload = {
      signature: 'report:update-last-appointment-date-at',
      body: {
        report: this.report.uuid,
        last_appointment_at: this.lastAppointmentAt.selectedDate ? DateTime.fromSQL(`${this.lastAppointmentAt.selectedDate} 00:00:00`).toFormat('yyyy-LL-dd HH:mm:ss') : '',
        reason: this.exemptionReasonPhrase,
      },
    };

    new Rpc()
      .rpcPost(payload)
      .then(() => {
        this.isLoading = false;
        this.$emit('reload');
        this.close();
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected get exemptionReasonPhrase(): string {
    return `${runtimeExemptionReasonsLabels[this.selectedRuntimeExemptionReason]}: ${this.exemptionReasonAddition}`;
  }
}
